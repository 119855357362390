*{
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.container1{
  display: flex;
}
main{
  width: 100%;
 
}
.sidebar{
  
  
  width: 250px;
  transition: all 0.5s;
  border-right: 1px solid rgb(216, 216, 216);
}
.top_section{
  display: flex;
  align-items: center;
  padding: 20px 15px;
}
.logo{
  display: flex;
  /* margin-left: 50px; */
  
}
.bars{
  font-size: 30px;

}
.link{
  display: flex;
  color: #344054;
  padding: 10px 15px;
  gap:15px;
  transition: all 0.5s;
  text-decoration: none;
}
.link:hover{
  background-color:  #00A7E1;
  color:white;
  transition: all 0.5s;
}
.active{
  background-color:  #00A7E1;
  color:white;
}
.icon, .link_text{
  font-size: 16px;
}

