/* SignUpForm.css */

.signup-form-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .signup-form-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .signup-form-container form {
    display: flex;
    flex-direction: column;
  }
  
  .signup-form-container input {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .signup-form-container button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .signup-form-container button:hover {
    background-color: #0056b3;
  }
  
  @media screen and (max-width: 480px) {
    .signup-form-container {
      width: 90%;
    }
  }
  