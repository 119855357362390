


/* Flight input field */
/* .input1{
  padding-bottom: 15px;
}
.input1 input{
  font-size: 16px;
  width: 30%;
  padding: 10px 20px;
  border-radius: 10px;
}
#departure{
  font-size: 16px;
  width: 30%;
  padding: 10px 20px;
  border-radius: 10px;
}
.departure-dropdown{
  font-size: 16px;
  width: 30%;
  padding: 10px 20px;
  border-radius: 10px;
}

.input2 input{
  font-size: 16px;
  width: 18%;
  padding: 10px 20px;
  border-radius: 10px;
}
.input3{
  display: flex;
}
.search{
  background-color: #FB8B24;
  color: white;
}
.para{
  color: white;
  margin-top: 10px;
}
.input2{
  display: flex;
} */
/* .dropdown{
  font-size: 16px;
  width: 35%;
  padding-right: 5px;
  border-radius: 10px;
} */
.multiCitySearch{
  font-size: 16px;
  background-color: #FB8B24;
  width: 20%;
  padding-left: 5px;
  border-radius: 10px;
}
.selc{
  padding: 10px 20px;
  border-radius: 10px;
}
.carasol img{
 width: 33.3%;
 padding-right: 15px;
}

.carasol{
  padding-top: 20px;
}

.why-use-ticketestan{
  background-color: #00A7E1;
}
/* .side-icon{
  color: white;
  background-color: white;
} */

.content{
  color: black;
}
.quick-link-box a{
  text-decoration: none;
}

.mobile-app{
  background-color: #00A7E1;
}

.earnpoints{
  background-color: 
  #004B65;
}


/* promo-section */

.btn-clr{
  background-color: #FB8B24;
  border-color: #FB8B24;
  color: white;
}

/* top flight */
.list-container {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.list-container a{
  text-decoration: none;
  color: #3498DB;
}
.list-item {
  flex: 1 0 25%; /* Adjust width as needed */
  margin: 0;
  padding: 10px;
  box-sizing: border-box;
}

.footer-section{
  background-color:#003A4F;
}
.hr-color{
  border: 0.5px solid #D7D7D7
}

.socialIcon li{
  list-style: none;
  padding: 20px;
}
.backgroundclrOne{
  background-color:#004B65;
  color: white;
}

.globalclr{
  background: #FB8B24;
  ;
}


