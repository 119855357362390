.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}
.input-group>.form-control, .input-group>.form-select {
    position: relative;
    flex: 1 1 auto;
    width: 100%;
    
}

.btn-primary:hover {
    color: #fff;
    background-color: #00A7E1;;
    border-color: #00A7E1;
}