/* .custom-btn {
    width: 165.59px;
    height: 80.32px;
    padding: 18.65px 37.3px;
    background-color: #00A7E1;
    border-radius: 6.05px;
    border: none;
    color: white;
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
    text-align: center;
    line-height: 1.2;
  }
  
  
  @media (max-width: 992px) {
    .custom-btn {
      width: 100%;
      height: auto;
      padding: 15px 30px;
      margin-right: 0;
    }
  } */

  .custom-button {
    background-color: white;
    color: black;
    border: 1px solid black;
    border-radius: 6px;
    margin: 5px;
    width: 100%;
    text-align: center;
    padding: 10px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .custom-button:hover {
    background-color: #00A7E1;
    color: white;
  }
  